/* Imports */
import { createStore } from 'vuex';

/* Modules */
import compare from './compare';
import customerGroups from './customerGroups';
import filters from './filters';
import kickers from './kickers';
import pricing from './pricing';
import products from './products';
import queryParams from './queryParams';
import contentfulFeatureFlags from './contentfulFeatureFlags';

/* Helpers */
import { getCountryAndStoreCode } from '../utils/mainUtils';

export const storeSettings = {
  modules: {
    filters,
    pricing,
    products,
    kickers,
    queryParams,
    compare,
    customerGroups,
    contentfulFeatureFlags,
  },
  state: {
    locale: '',
    storeCode: '',
    countryCode: '',
    categoryKey: '',
    comparePageUrl: '',
    buyServiceBase: '',
    publicPath: '',
    content: {},
    appIsReady: false,
    isLoading: true,
    translations: [],
  },
  getters: {
    getLocale: (state) => state.locale,
    getStoreCode: (state) => state.storeCode,
    getCountryCode: (state) => state.countryCode,
    getCategoryKey: (state) => state.categoryKey,
    getAppIsReady: (state) => state.appIsReady,
    getIsLoading: (state) => state.isLoading,
    getComparePageUrl: (state) => state.comparePageUrl,
    getBuyServiceBase: (state) => state.buyServiceBase,
    getPublicPath: (state) => state.publicPath,
    getContent: (state) => state.content,
    getSeoTitle: (state) => state.content?.seoTitle,
    getTranslations: (state) => state.translations,
  },
  mutations: {
    setLocale: (state, value) => {
      state.locale = value;
    },
    setStoreCode: (state, value) => {
      state.storeCode = value;
    },
    setCountryCode: (state, value) => {
      state.countryCode = value;
    },
    setCategoryKey: (state, value) => {
      state.categoryKey = value;
    },
    setAppIsReady: (state, value) => {
      state.appIsReady = value;
    },
    setIsLoading: (state, value) => {
      state.isLoading = value;
    },
    setComparePageUrl: (state, value) => {
      state.comparePageUrl = value;
    },
    setBuyServiceBase: (state, value) => {
      state.buyServiceBase = value;
    },
    setPublicPath: (state, value) => {
      state.publicPath = value;
    },
    setContent: (state, value) => {
      state.content = value;
    },
    setTranslations: (state, value) => {
      state.translations = value;
    },
  },
  actions: {
    loadLocale: ({ commit }, value) => {
      commit('setLocale', value || 'en-US');
    },
    loadStoreCode: ({ commit }, value) => {
      commit('setStoreCode', value);
    },
    loadCountryCode: ({ commit }, value) => {
      commit('setCountryCode', value);
    },
    loadComparePageUrl: ({ commit }, value) => {
      commit('setComparePageUrl', value);
    },
    loadCategoryKey: ({ commit }, value = '') => {
      commit('setCategoryKey', value);
    },
    setAppIsReady: ({ commit }, value) => {
      commit('setAppIsReady', value);
    },
    setIsLoading: ({ commit }, value) => {
      commit('setIsLoading', value);
    },
    loadBuyServiceBase: ({ commit }, value) => {
      commit('setBuyServiceBase', value);
    },
    loadPublicPath: ({ commit }, value) => {
      commit('setPublicPath', value);
    },
    loadContent: ({ commit }, value) => {
      commit('setContent', value);
    },
    async loadInitialState({ commit, dispatch }) {
      const {
        pageContent,
        locale,
        buyServiceBase,
        publicPath,
        appOrigin,
        translations,
      } = window?.content || {};

      // Inject Contentful Feature Flags
      dispatch('fetchContentfulEnvSettings');
      commit('setLocale', locale);
      commit('setContent', pageContent);
      commit('setBuyServiceBase', buyServiceBase);
      commit('setPublicPath', publicPath);
      commit('setTranslations', translations);

      // Construct Compare Page Url
      const comparePageUrl = `${appOrigin}/${locale}/compare/`;
      commit('setComparePageUrl', comparePageUrl);

      const { countryCode, storeCode } = getCountryAndStoreCode(locale);
      commit('setStoreCode', storeCode);
      commit('setCountryCode', countryCode);

      try {
        await dispatch('setCustomerGroups');
      } catch (error) {
        console.log(`setCustomerGroups: ${error}`);
      } finally {
        commit('setAppIsReady', true);
      }
    },
  },
};

export default createStore(storeSettings);
